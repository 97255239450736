import AIAnalysisBox from "components/ai/AianalysisBox";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const PointsPerMonth = (props: { data: any }) => {
    const { data } = props;

    const [barSerie, setBarSerie] = useState<any[]>([
        {
            name: "Chargement...",
            data: [],
        },
        {
            name: "Chargement...",
            data: [],
        },
    ]);

    const [barOptions, setBarOptions] = useState<any>({
        chart: {
            type: "bar",
            height: 350,
            stacked: true,
            toolbar: {
                show: false,
            },
            zoom: {
                enabled: false,
            },
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    legend: {
                        position: "bottom",
                        offsetX: -10,
                        offsetY: 0,
                    },
                },
            },
        ],
        plotOptions: {
            bar: {
                horizontal: false,
                borderRadius: 10,
                borderRadiusApplication: "end", // 'around', 'end'
                borderRadiusWhenStacked: "last", // 'all', 'last'
                dataLabels: {
                    formatter: function (val: any) {
                        return val + "€";
                    },
                    total: {
                        enabled: true,
                        style: {
                            fontSize: "13px",
                            fontWeight: 900,
                        },
                    },
                },
            },
        },
        dataLabels: {
            formatter: function (val: any) {
                return val + "";
            },
            style: {
                fontSize: "10px",
            },
        },
        xaxis: {
            categories: [
                "Janvier",
                "Février",
                "Mars",
                "Avril",
                "Mai",
                "Juin",
                "Juillet",
                "Août",
                "Septembre",
                "Octobre",
                "Novembre",
                "Décembre",
            ],
        },
        legend: {
            position: "top",
            offsetY: 0,
        },
        fill: {
            opacity: 1,
        },
    });

    useEffect(() => {
        if (!data || !data.pointsAnalytics) return;
        setBarSerie([
            {
                name: "Points clients existants",
                data: data.pointsAnalytics.login,
            },
            {
                name: "Points nouveaux clients",
                data: data.pointsAnalytics.register,
            },
        ]);
    }, [data]);

    return (
        <div className="mt-3 border rounded-xl p-6 col-span-1 md:col-span-3">
            <h1 className="text-lg font-bold">Points attribués par mois</h1>
            <ReactApexChart
                options={barOptions as any}
                series={barSerie as any}
                type="bar"
                width="100%"
                height={data?.aiAnalyse ? "50%" : "85%"}
            />
            {data?.aiAnalyse && (
                <AIAnalysisBox title="Analyse IA" content={data?.aiAnalyse} />
            )}
        </div>
    );
};

export default PointsPerMonth;
