import { BiSolidMagicWand } from "react-icons/bi";

const AIAnalysisBox = (props: { title: string; content: string }) => {
    const { title, content } = props;
    return (
        <div className="relative mt-2 mx-auto bg-white shadow-lg rounded-lg overflow-hidden border">
            <p className="absolute bottom-1 right-2 text-[11px] text-gray-700">
                Powered by llama3
            </p>
            <div className="bg-gradient-to-r from-primary to-blue-300 p-2 flex items-center">
                <BiSolidMagicWand className="text-white mr-2" size={24} />
                <h2 className="text-sm font-bold text-white">{title}</h2>
            </div>
            <div className="p-4">
                <p className="text-gray-700 text-sm mb-2">{content}</p>
            </div>
        </div>
    );
};

export default AIAnalysisBox;
